
import Tooltip from 'rc-tooltip';
import config from '../../../assets/config/config.json';
import { SummonerSpellDTO, SummonerSpellsDTO } from '../../../DTOs/MatchHistoryDTOs/SummonerSpellsDTO';
interface SummonerSpellProps {
    version: string;
    summonerJson: SummonerSpellsDTO | undefined;
    spellID: number;
    alt?: "";
}
export const SummonerSpell = (props: SummonerSpellProps) => {



    const getSummonerByKey = (key: string): SummonerSpellDTO | null => {
        // Zugriff auf das "data"-Objekt
        if (props.summonerJson) {
            const spells = props.summonerJson.data;

            // Iteriere durch alle Zauber und prüfe, ob der Key übereinstimmt
            for (const spellKey in spells) {
                if (spells[spellKey].key === key) {
                    return spells[spellKey]; // Gebe die ID des Zaubers zurück
                }
            }
        }
        return null
    }

    const SummonerSpellTooltip = () => {
        const spell: SummonerSpellDTO | null = getSummonerByKey(props.spellID.toString())

        if (spell) {
            return (<div className='summonerspell-tooltip'>
                <span className='summonerspell-tooltip-header'>
                    <span className='summonerspell-tooltip-name'>
                        {spell.name}
                    </span>
                    <span className='summonerspell-tooltip-cooldown'>
                        <i className='pi pi-stopwatch' />
                        <span>{spell.cooldown}s</span>
                    </span>
                </span>
                <span className='summonerspell-tooltip-description'>{spell.description}</span>
            </div>);
        }
    }
    return (<span className='summonerspell'>
        <Tooltip mouseEnterDelay={0.25} overlay={SummonerSpellTooltip} placement='bottom' zIndex={9999}>
            <img alt={props.alt || ""} src={config.riot_champ_img_base_url + props.version + "/img/spell/" + getSummonerByKey(props.spellID.toString())?.id + ".png"} />
        </Tooltip>
    </span>)
}