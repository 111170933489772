import img_top from '../../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../../assets/imgs/roles/Position_Support.png';

interface RoleTagProps {
    role: string;
}

export const RoleTag = (props: RoleTagProps) => {

    const mapRole = () => {
        if (props.role === "TOP")
            return img_top
        if (props.role === "JUNGLE")
            return img_jungle
        if (props.role === "MIDDLE")
            return img_mid
        if (props.role === "BOTTOM")
            return img_bot
        if (props.role === "UTILITY")
            return img_support

        return "undefined Role"
    }

    return (
        <span className={"tag-roletag"}>
            <span className='role-img-wrapper'>
                <img alt={props.role} src={mapRole()} />
            </span>
        </span>
    )
}