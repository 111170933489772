import { useState } from "react";
import { ItemsDTO } from "../../../DTOs/MatchHistoryDTOs/ItemsDTO";
import { matchPlayerDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerDTO";
import { PerkDTO } from "../../../DTOs/MatchHistoryDTOs/PerkDTO";
import { SummonerSpellsDTO } from "../../../DTOs/MatchHistoryDTOs/SummonerSpellsDTO";
import { ChampImg } from "../ChampImg";
import { Dialog } from 'primereact/dialog';
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import { CopyLinkOptions, GetCopyLink } from "../../../services/SearchLinkService";
import { MatchPlayerCard } from "./MatchPlayerCard";
import Tooltip from "rc-tooltip";

import img_top from '../../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../../assets/imgs/roles/Position_Support.png';

interface MatchTeamChampProps {
    playerData: matchPlayerDTO;
    version: string;
    summonerJson: SummonerSpellsDTO | undefined;
    runesJson: PerkDTO[] | undefined;
    itemJson: ItemsDTO | undefined;
    HandleBan: (args: string) => void;
    BanList: string[];
    playerName: string;
    match: matchDTO;
    statsShowFilter: statsShowFilter;
    CopyLinkOptions: CopyLinkOptions;
}

export const MatchTeamChamp = (props: MatchTeamChampProps) => {

    const isPlayer = () => {
        if (props.playerName === props.playerData.name) {
            return "matchteamchamp-header-img-activeplayer"
        }
        return ""
    }

    const getRoleImg = (role: string) => {
        switch (role) {
            case 'TOP':
                return img_top;
            case 'JUNGLE':
                return img_jungle;
            case 'MIDDLE':
                return img_mid;
            case 'BOTTOM':
                return img_bot;
            case 'UTILITY':
                return img_support;
        }
    }

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    return (<>{props.playerData && <>
        <Dialog className="matchteamchamp-info" header={""} visible={dialogVisible} modal={false} onHide={() => { if (!dialogVisible) return; setDialogVisible(false); }}>
            <div className="matchteamchamp-info-header">
                <span>
                    {props.playerData.name}
                </span>
                <span>
                    <a href={GetCopyLink({ ...props.CopyLinkOptions, summonernames: props.playerData.name } as CopyLinkOptions)} rel="noreferrer" target="_blank">
                        <i className="pi pi-external-link" />
                    </a>
                </span>
            </div>
            <div className="matchteamchamp-info-card">
                <MatchPlayerCard match={props.match} version={props.version} summonerJson={props.summonerJson}
                    runesJson={props.runesJson} itemJson={props.itemJson} HandleBan={props.HandleBan}
                    BanList={props.BanList} statsShowFilter={props.statsShowFilter} playerData={props.playerData} />
            </div>
        </Dialog>
        <div className="matchteamchamp">
            <span className="matchteamchamp-header">
                <span className={"matchteamchamp-header-img " + isPlayer()}>
                    <Tooltip overlay={<img className="ChampStatsOverview-Champ-Tooltip-img" src={getRoleImg(props.playerData.role)} alt={props.playerData.role} />} mouseEnterDelay={0.25} zIndex={9999} placement="top">
                        <span className="ChampStatsOverview-Champ-Img">
                            <ChampImg name={props.playerData.champ} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} />
                        </span>
                    </Tooltip>
                </span>
                <span className="matchteamchamp-header-info">
                    <button onClick={() => setDialogVisible(!dialogVisible)}><span>i</span></button>
                </span>
            </span>
            <span className="matchteamchamp-kda">
                <span className="matchteamchamp-kda-main">{props.playerData.kills}/{props.playerData.deaths}/{props.playerData.assists}</span>
                <span className="matchteamchamp-kda-calculated">
                    {props.playerData.kda as number >= 1 ?
                        props.playerData.kda as number >= 3 ?
                            <b className="champion-stats-kda-positiv">{props.playerData.kda}</b> :
                            <b className="champion-stats-kda-neutral">{props.playerData.kda}</b> :
                        <b className="champion-stats-kda-negativ">{props.playerData.kda}</b>}
                    <b>KDA</b>
                </span>
            </span>
        </div>
    </>}
    </>);
}