import { ItemsDTO } from "../../../DTOs/MatchHistoryDTOs/ItemsDTO";
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { matchPlayerDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerDTO";
import { PerkDTO } from "../../../DTOs/MatchHistoryDTOs/PerkDTO";
import { SummonerSpellsDTO } from "../../../DTOs/MatchHistoryDTOs/SummonerSpellsDTO";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import { CopyLinkOptions } from "../../../services/SearchLinkService";
import { MatchTeamChamp } from "./MatchTeamChamp";

interface MatchSideCardProps {
    playerSide: matchPlayerDTO[];
    side: string;
    version: string;
    summonerJson: SummonerSpellsDTO | undefined;
    runesJson: PerkDTO[] | undefined;
    itemJson: ItemsDTO | undefined;
    HandleBan: (args: string) => void;
    BanList: string[];
    win: boolean;
    playerName: string;
    match: matchDTO;
    statsShowFilter: statsShowFilter;
    CopyLinkOptions: CopyLinkOptions;
}

export const MatchSideCard = (props: MatchSideCardProps) => {
    return (<div className={"matchsidecard matchsidecard-color-" + props.side}>
        {props.win ?
            <span className="matchsidecard-win"><i className="pi pi-trophy" /></span>
            : <span className="matchsidecard-lost"><i className="pi pi-times" /></span>}
        {props.playerSide.map((player => {
            return (
                <MatchTeamChamp playerData={player}
                    version={props.version} summonerJson={props.summonerJson} runesJson={props.runesJson}
                    itemJson={props.itemJson} HandleBan={props.HandleBan} BanList={props.BanList} playerName={props.playerName}
                    match={props.match} statsShowFilter={props.statsShowFilter} CopyLinkOptions={props.CopyLinkOptions} />
            );
        }))}
    </div>);
}


