import { MatchPlayerCardDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerCardDTO"
import { PerkDTO } from "../../../DTOs/MatchHistoryDTOs/PerkDTO"
import config from '../../../assets/config/config.json';

export const getRuneIconPath = (runesJson: PerkDTO[] | undefined, id: number) => {
    if (runesJson) {
        const xOut = runesJson.filter(runes => runes.id === id)[0].iconPath.replace("/lol-game-data/assets/v1/", "img/")
        if (xOut)
            return xOut
    }
    return "error"
}

export const getRuneName = (runesJson: PerkDTO[] | undefined, id: number) => {
    if (runesJson) {
        const xOut = runesJson?.filter(runes => runes.id === id)[0]?.name
        if (xOut)
            return xOut
    }
    return ""
}

export const getRuneDescription = (runesJson: PerkDTO[] | undefined, id: number) => {
    if (runesJson) {
        const xOut = runesJson?.filter(runes => runes.id === id)[0]?.shortDesc
        if (xOut)
            return xOut
    }
    return ""
}


export const RunesTooltip = (props: MatchPlayerCardDTO) => {
    return (<div className="tooltip-runes">
        <div className="tooltip-runes-main">
            <span className="rune rune-main">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[0].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[0].perk)}</span>
                </span>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[0].perk) || "") }} />
            </span>
            <span className="rune">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[1].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[1].perk)}</span>
                </span>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[1].perk) || "") }} />
            </span>
            <span className="rune">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[2].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[2].perk)}</span>
                </span>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[2].perk) || "") }} />
            </span>
            <span className="rune">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[3].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[3].perk)}</span>
                </span>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[3].perk) || "") }} />
            </span>
        </div>
        <div className="tooltip-runes-secundary">
            <span className="rune">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[0].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[0].perk)}</span>
                </span>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[0].perk) || "") }} />
            </span>
            <span className="rune">
                <span className="rune-header">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[1].perk)} />
                    <span className="rune-header-txt">{getRuneName(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[1].perk)}</span>
                </span><span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getRuneDescription(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "subStyle")[0].selections[1].perk) || "") }} />
            </span>
            <div className="tooltip-runes-secundary-perks">
                <span className="rune-stat">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.statPerks.defense)} />
                    <span className="rune-txt" dangerouslySetInnerHTML={{
                        __html: (getRuneDescription(props.runesJson, props.playerData.runes.statPerks.defense))
                    }} />
                </span>
                <span className="rune-stat">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.statPerks.flex)} />
                    <span className="rune-txt" dangerouslySetInnerHTML={{
                        __html: (getRuneDescription(props.runesJson, props.playerData.runes.statPerks.flex))
                    }} />
                </span>
                <span className="rune-stat">
                    <img alt={""} className="rune-img" src={config.riot_champ_img_base_url +
                        getRuneIconPath(props.runesJson, props.playerData.runes.statPerks.offense)} />
                    <span className="rune-txt" dangerouslySetInnerHTML={{
                        __html: (getRuneDescription(props.runesJson, props.playerData.runes.statPerks.offense))
                    }} />
                </span>
            </div>
        </div>

    </div>);
}