import { useEffect, useState } from "react";
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { MatchHistoryDTO } from "../../../DTOs/MatchHistoryDTOs/MatchHistoryDTO";
import { Match } from "./Match";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import { LoadingIcon } from "../LoadingIcon";
import { SummonerSpellsDTO } from "../../../DTOs/MatchHistoryDTOs/SummonerSpellsDTO";
import { activeRolesValues } from "../../../DTOs/ActiveRolesDTO";
import { FilterStats } from "../../../DTOs/FilterStatsDTO";
import { PerkDTO } from "../../../DTOs/MatchHistoryDTOs/PerkDTO";
import { ItemsDTO } from "../../../DTOs/MatchHistoryDTOs/ItemsDTO";
import { CopyLinkOptions } from "../../../services/SearchLinkService";

interface MatchHistoryTabProps {
  name: string;
  version: string;
  summonerJson: SummonerSpellsDTO | undefined;
  runesJson: PerkDTO[] | undefined;
  itemJson: ItemsDTO | undefined;
  matchHistoryLoading: boolean;
  MatchHistoryData: MatchHistoryDTO[];
  HandleBan: (args: string) => void;
  BanList: string[];
  activeRoles: activeRolesValues;
  statsShowFilter: statsShowFilter;
  filter: FilterStats;
  CopyLinkOptions: CopyLinkOptions;
}

export const MatchHistoryTab = (props: MatchHistoryTabProps) => {
  const [matchHistoryData, setMatchHistoryData] = useState<matchDTO[]>([]);
  const [maxMatches, setMaxMatches] = useState(5);
  useEffect(() => {
    if(props.MatchHistoryData){
      props.MatchHistoryData.forEach((player: MatchHistoryDTO) => {
        if (player.player === props.name) {
          setMatchHistoryData(player.playerMatches);
          setMaxMatches(30);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.MatchHistoryData]);

  const isActiveByFilter = (match: matchDTO) =>{

    if(match.queue === "normal" && props.filter.normalQ !== false)
      return true
    if(match.queue === "soloQ" && props.filter.soloQ !== false)
      return true
    if(match.queue === "flexQ" && props.filter.flexQ !== false)
      return true
    if(match.queue === "clash" && props.filter.clash !== false)
      return true
    if(match.queue === "tournament" && props.filter.tournament !== false)
      return true
    
    return false
  }

  return (
    <>{(matchHistoryData && !props.matchHistoryLoading)  ?
      <>
        {matchHistoryData.length > 0 &&
          matchHistoryData.slice(0, maxMatches).map((match: matchDTO) => {
            return (<>{ isActiveByFilter(match) &&
              <span key={props.name + match.matchId}>
                <Match
                  match={match}
                  version={props.version}
                  summonerJson={props.summonerJson}
                  runesJson={props.runesJson}
                  itemJson={props.itemJson}
                  HandleBan={props.HandleBan}
                  BanList={props.BanList}
                  statsShowFilter={props.statsShowFilter}
                  activeRoles={props.activeRoles}
                  filter={props.filter} 
                  CopyLinkOptions={props.CopyLinkOptions} />
              </span>}
            </>
            );
          })}
      </> : <LoadingIcon />}
    </>
  );
};
