
import React, { useEffect, useState } from "react";
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { Tag } from "./Tag";
import { matchPlayerDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerDTO";
import { LoadingIcon } from "../LoadingIcon";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import { SummonerSpellsDTO } from "../../../DTOs/MatchHistoryDTOs/SummonerSpellsDTO";
import { activeRolesValues } from "../../../DTOs/ActiveRolesDTO";
import { FilterStats } from "../../../DTOs/FilterStatsDTO";
import { RoleTag } from "./RoleTag";
import { MatchSideCard } from "./MatchTeamCard";
import { MatchPlayerCard } from "./MatchPlayerCard";
import { PerkDTO } from "../../../DTOs/MatchHistoryDTOs/PerkDTO";
import { ItemsDTO } from "../../../DTOs/MatchHistoryDTOs/ItemsDTO";
import { CopyLinkOptions, GetCopyLink } from "../../../services/SearchLinkService";
import { Dialog } from "primereact/dialog";
interface MatchProps {
  match: matchDTO;
  version: string;
  summonerJson: SummonerSpellsDTO | undefined;
  runesJson: PerkDTO[] | undefined;
  itemJson: ItemsDTO | undefined;
  HandleBan: (args: string) => void;
  BanList: string[];
  statsShowFilter: statsShowFilter;
  activeRoles: activeRolesValues;
  filter: FilterStats;
  CopyLinkOptions: CopyLinkOptions;
}

export const Match = (props: MatchProps) => {


  const convertNumberToTwoDigitString = (x:number) =>{
    if(x < 10 && x >= 0){
      return "0"+x.toString()
    }else{
      return x.toString()
    }
  }

  const getDate = (timestamp: number) => {
    let time = new Date(timestamp);
    return (
      convertNumberToTwoDigitString(time.getDate()) +
      "." +
      convertNumberToTwoDigitString(time.getMonth()) +
      "." +
      time.getFullYear().toString() +
      " - " +
      convertNumberToTwoDigitString(time.getHours()) +
      ":" +
      convertNumberToTwoDigitString(time.getMinutes())
    );
  };


  const mapQueue = (queue: string) => {
    if (queue === "normal")
      return "DRAFT"
    if (queue === "soloQ")
      return "SOLO/DUO"
    if (queue === "flexQ")
      return "FLEX"
    if (queue === "clash")
      return "CLASH"
    if (queue === "tournament")
      return "TOURNAMENT"

    return "undefined QUEUE"
  }

  const isActiveByRole = () => {
    if (playerData?.role === "TOP")
      return props.activeRoles.top
    if (playerData?.role === "JUNGLE")
      return props.activeRoles.jungle
    if (playerData?.role === "MIDDLE")
      return props.activeRoles.mid
    if (playerData?.role === "BOTTOM")
      return props.activeRoles.bot
    if (playerData?.role === "UTILITY")
      return props.activeRoles.support

    return false
  }

  const isActiveByStats = () => {
    if (playerData) {
      if (playerData.kda >= props.filter.kda
        && playerData.goldPerMinute >= props.filter.gold
        && playerData.csPerMinute >= props.filter.cs) {
        return true
      }
    }
    return false
  }

  const [winclass, setWinclass] = useState("match-win");
  const [playerData, setPlayerData] = useState<matchPlayerDTO>();
  useEffect(() => {
    if (props.match.win) {
      setWinclass("match-win");
    } else {
      setWinclass("match-lost");
    }
    if (props.match.playerSide === "blue") {
      props.match.blueSide.forEach((player) => {
        if (player.name === props.match.name) {
          setPlayerData(player);
        }
      });
    } else {
      props.match.redSide.forEach((player) => {
        if (player.name === props.match.name) {
          setPlayerData(player);
        }
      });
    }
  }, [props]);

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const MatchHeadline = () => {
    return (<>{playerData && <div className="match-headline">
      <span className="match-headline-time">
        {getDate(props.match.gameCreation)}
      </span>
      <span className="match-headline-tags">

        <RoleTag role={playerData.role} />

        <Tag tag={mapQueue(props.match.queue)} color="gray" />

        {props.match.playerSide === "red" && (
          <Tag tag="Redside" color="redside" />
        )}
        {props.match.playerSide === "blue" && (
          <Tag tag="Blueside" color="blue" />
        )}
        {playerData?.firstBloodKill && (
          <Tag tag="First Blood" color="red" />
        )}

        {playerData?.objectivesStolen !== undefined &&
          playerData.objectivesStolen === 1 && (
            <Tag
              tag={playerData.objectivesStolen + " Objective Stolen"}
              color="orange"
            />
          )}
        {playerData?.objectivesStolen !== undefined &&
          playerData.objectivesStolen > 1 && (
            <Tag
              tag={playerData.objectivesStolen + " Objectives Stolen"}
              color="orange"
            />
          )}

        {playerData?.baronKills !== undefined &&
          playerData.baronKills === 1 && (
            <Tag
              tag={playerData.baronKills + " Baron Kill"}
              color="purple"
            />
          )}
        {playerData?.baronKills !== undefined &&
          playerData.baronKills > 1 && (
            <Tag
              tag={playerData.baronKills + " Baron Kills"}
              color="purple"
            />
          )}

        {playerData?.dragonKills !== undefined &&
          playerData.dragonKills === 1 && (
            <Tag
              tag={playerData.dragonKills + " Dragon Kill"}
              color="orange"
            />
          )}
        {playerData?.dragonKills !== undefined &&
          playerData.dragonKills > 1 && (
            <Tag
              tag={playerData.dragonKills + " Dragon Kills"}
              color="orange"
            />
          )}

        {playerData?.tripleKills !== undefined &&
          playerData.tripleKills === 1 && (
            <Tag
              tag={playerData.tripleKills + " Triple Kill"}
              color="yellow"
            />
          )}
        {playerData?.tripleKills !== undefined &&
          playerData.tripleKills > 1 && (
            <Tag
              tag={playerData.tripleKills + " Triple Kills"}
              color="yellow"
            />
          )}

        {playerData?.quadraKills !== undefined &&
          playerData.quadraKills === 1 && (
            <Tag
              tag={playerData.quadraKills + " Quadra Kill"}
              color="yellow"
            />
          )}
        {playerData?.quadraKills !== undefined &&
          playerData.quadraKills > 1 && (
            <Tag
              tag={playerData.quadraKills + " Quadra Kills"}
              color="yellow"
            />
          )}

        {playerData?.pentaKills !== undefined &&
          playerData.pentaKills === 1 && (
            <Tag
              tag={playerData.pentaKills + " Penta Kill"}
              color="yellow"
            />
          )}
        {playerData?.pentaKills !== undefined &&
          playerData.pentaKills > 1 && (
            <Tag
              tag={playerData.pentaKills + " Penta Kills"}
              color="yellow"
            />
          )}
      </span>
    </div>}</>);
  }

  const MatchFullData = () => {
    return (<>{playerData && <div className="match-fulldata">
      <div className="match-fulldata-side match-fulldata-side-blue">
        {props.match.blueSide.map(player => {
          return (
            <div className="match-fulldata-side-card">
              <div className="match-fulldata-side-card-header">
                <span>
                  {player.name}
                </span>
                <span>
                  <a href={GetCopyLink({ ...props.CopyLinkOptions, summonernames: player.name } as CopyLinkOptions)} rel="noreferrer" target="_blank">
                    <i className="pi pi-external-link" />
                  </a>
                </span>
              </div>
              <div className="match-fulldata-side-card-content">
                <MatchPlayerCard match={props.match} version={props.version} summonerJson={props.summonerJson}
                  runesJson={props.runesJson} itemJson={props.itemJson} HandleBan={props.HandleBan}
                  BanList={props.BanList} statsShowFilter={props.statsShowFilter} playerData={player} currentPlayer={props.match.name} />
              </div>
            </div>)
        })}
      </div>
      <div className="match-fulldata-side match-fulldata-side-red">
        {props.match.redSide.map(player => {
          return (
            <div className="match-fulldata-side-card">
              <div className="match-fulldata-side-card-header">
                <span>
                  {player.name}
                </span>
                <span>
                  <a href={GetCopyLink({ ...props.CopyLinkOptions, summonernames: player.name } as CopyLinkOptions)} rel="noreferrer" target="_blank">
                    <i className="pi pi-external-link" />
                  </a>
                </span>
              </div>
              <div className="match-fulldata-side-card-content">
                <MatchPlayerCard match={props.match} version={props.version} summonerJson={props.summonerJson}
                  runesJson={props.runesJson} itemJson={props.itemJson} HandleBan={props.HandleBan}
                  BanList={props.BanList} statsShowFilter={props.statsShowFilter} playerData={player} currentPlayer={props.match.name} />
              </div>
            </div>)
        })}
      </div>
    </div>}</>)
  }

  const getSideNames =(sidePlayers: matchPlayerDTO[] ) =>{
    let outStr = ""
    for(let player of sidePlayers){
      outStr = outStr + ","+player.name
    }

    return outStr.slice(1, outStr.length)
  }
  return (
    <>
      {isActiveByRole() && isActiveByStats() &&
        <div className={"match " + winclass}>
          {playerData?.name ? (
            <>
              <Dialog className="matchteamchamp-info" header={""} visible={dialogVisible} onHide={() => { if (!dialogVisible) return; setDialogVisible(false); }}>
                <div className="match-fulldata-wrapper">
                  <div className="match-fulldata-header">

                    <div className="tag tag-blue match-fulldata-tag">
                      {(props.match.win && props.match.playerSide === "blue") || (!props.match.win && props.match.playerSide === "red") ?
                        <span className="matchsidecard-win"><i className="pi pi-trophy" /></span>
                        : <span className="matchsidecard-lost"><i className="pi pi-times" /></span>}
                      <span>Blueside</span>
                      <span className="match-fulldata-tag-link-blue">
                        <a href={GetCopyLink({ ...props.CopyLinkOptions, summonernames: getSideNames(props.match.blueSide) } as CopyLinkOptions)} rel="noreferrer" target="_blank">
                          <i className="pi pi-external-link" />
                        </a>
                      </span>
                    </div>
                    <MatchHeadline />
                    <div className="tag tag-red match-fulldata-tag">
                      {(props.match.win && props.match.playerSide === "red") || (!props.match.win && props.match.playerSide === "blue") ?
                        <span className="matchsidecard-win"><i className="pi pi-trophy" /></span>
                        : <span className="matchsidecard-lost"><i className="pi pi-times" /></span>}
                      <span>Redside</span>
                      <span className="match-fulldata-tag-link-red">
                        <a href={GetCopyLink({ ...props.CopyLinkOptions, summonernames: getSideNames(props.match.redSide) } as CopyLinkOptions)} rel="noreferrer" target="_blank">
                          <i className="pi pi-external-link" />
                        </a>
                      </span>
                    </div>
                  </div>
                  <MatchFullData />
                </div>
              </Dialog>
              <div className="match-headline-wrapper">
                <MatchHeadline />
                <div className="match-headline-info">
                  <button onClick={() => setDialogVisible(!dialogVisible)}><span>i</span></button>
                </div>
              </div>

              <div className="match-content">
                <div className="matchplayercard">
                  <MatchPlayerCard match={props.match} version={props.version}
                    summonerJson={props.summonerJson} runesJson={props.runesJson}
                    HandleBan={props.HandleBan} BanList={props.BanList}
                    statsShowFilter={props.statsShowFilter} playerData={playerData} itemJson={props.itemJson} />
                </div>
                <MatchSideCard playerSide={props.match.blueSide} side="blue" version={props.version} summonerJson={props.summonerJson} runesJson={props.runesJson}
                  itemJson={props.itemJson} HandleBan={props.HandleBan} BanList={props.BanList} win={(props.match.win && props.match.playerSide === "blue") || (!props.match.win && props.match.playerSide === "red")}
                  playerName={props.match.name} match={props.match} statsShowFilter={props.statsShowFilter} CopyLinkOptions={props.CopyLinkOptions} />
                <MatchSideCard playerSide={props.match.redSide} side="red" version={props.version} summonerJson={props.summonerJson} runesJson={props.runesJson}
                  itemJson={props.itemJson} HandleBan={props.HandleBan} BanList={props.BanList} win={(props.match.win && props.match.playerSide === "red") || (!props.match.win && props.match.playerSide === "blue")}
                  playerName={props.match.name} match={props.match} statsShowFilter={props.statsShowFilter} CopyLinkOptions={props.CopyLinkOptions} />
              </div>
            </>
          ) : (
            <LoadingIcon />
          )}
        </div>
      }
    </>
  );
};
