import { ChampImg } from "../ChampImg";
import { Item } from "./Item";
import { SummonerSpell } from "./SummonerSpell";
import config from '../../../assets/config/config.json';
import Tooltip from "rc-tooltip";
import { MatchPlayerCardDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerCardDTO";
import { getRuneIconPath, RunesTooltip } from "./RunesTooltip";

import img_top from '../../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../../assets/imgs/roles/Position_Support.png';

export const MatchPlayerCard = (props: MatchPlayerCardDTO) => {

    const isCurrentPlayer = () => {
        if (props.currentPlayer && props.currentPlayer === props.playerData.name) {
            return "matchplayercard-overview-img-active"
        }
        return ""

    }

    const getRoleImg = (role: string) => {
        switch (role) {
            case 'TOP':
                return img_top;
            case 'JUNGLE':
                return img_jungle;
            case 'MIDDLE':
                return img_mid;
            case 'BOTTOM':
                return img_bot;
            case 'UTILITY':
                return img_support;
        }
    }

    return (<>
        <div className="matchplayercard-overview">
            <span className={"matchplayercard-overview-img " + isCurrentPlayer()}>
                <Tooltip overlay={<img className="ChampStatsOverview-Champ-Tooltip-img" src={getRoleImg(props.playerData.role)} alt={props.playerData.role} />} mouseEnterDelay={0.25} zIndex={9999} placement="top">
                    <span className="ChampStatsOverview-Champ-Img">
                        <ChampImg
                            name={props.playerData.champ}
                            version={props.version}
                            banList={props.BanList}
                            HandleBan={props.HandleBan}
                        />
                    </span>
                </Tooltip>
                <span className="matchplayercard-overview-img-runes">
                    {props.runesJson &&
                        <Tooltip mouseEnterDelay={0.25} overlay={RunesTooltip(props)} zIndex={9999}>
                            <img alt={""} className="matchplayercard-overview-img-runes-img"
                                src={config.riot_champ_img_base_url +
                                    getRuneIconPath(props.runesJson, props.playerData.runes.styles.filter(style => style.description === "primaryStyle")[0].selections[0].perk)} />
                        </Tooltip>
                    }
                </span>
            </span>
            <span className="matchplayercard-overview-sums">
                <SummonerSpell version={props.version} spellID={props.playerData.summonerSpell1} summonerJson={props.summonerJson} />
                <SummonerSpell version={props.version} spellID={props.playerData.summonerSpell2} summonerJson={props.summonerJson} />
            </span>
        </div>
        <div className="matchplayercard-content">
            <div className="matchplayercard-content-kda">
                <div className="matchplayercard-content-kda-main">
                    {props.playerData.kills}/{props.playerData.deaths}/{props.playerData.assists}
                </div>
                <div className="matchplayercard-content-kda-calculated">
                    {props.playerData.kda as number >= 1 ?
                        props.playerData.kda as number >= 3 ?
                            <b className="champion-stats-kda-positiv">{props.playerData.kda}</b> :
                            <b className="champion-stats-kda-neutral">{props.playerData.kda}</b> :
                        <b className="champion-stats-kda-negativ">{props.playerData.kda}</b>}
                    <b> KDA</b>
                </div>
            </div>
            <div className="matchplayercard-content-items">
                <div className="matchplayercard-content-items-default">
                    {props.playerData.items.slice(0, props.playerData.items.length - 1).map((item: number) => {
                        return (<Item itemID={item} version={props.version} itemJson={props.itemJson} />)
                    })}
                </div>
                <div className="matchplayercard-content-items-vision">
                    <div className="matchplayercard-content-items-vision-img-wrapper">
                        <Item itemID={props.playerData.items[props.playerData.items.length - 1]} version={props.version} itemJson={props.itemJson} />
                    </div>
                    <div className="matchplayercard-content-items-vision-txt-wrapper">
                        <Tooltip mouseEnterDelay={0.25} overlay={<span className="resetFont">Vision Score</span>} placement="left" zIndex={9999}><i className="pi pi-eye" /></Tooltip>
                        <span>{props.playerData.visionScore}</span>
                    </div>
                </div>
            </div>
            <div className="matchplayercard-content-bonus">
                {props.statsShowFilter.gold && (
                    <span className="matchplayercard-content-bonus-stat">
                        {" "}
                        <b>Gold/min:</b> <span>{props.playerData.goldPerMinute}</span>{" "}
                    </span>
                )}

                {props.statsShowFilter.cs && (
                    <span className="matchplayercard-content-bonus-stat">
                        {" "}
                        <b>CS/min:</b> <span>{props.playerData.csPerMinute}</span>{" "}
                    </span>
                )}
            </div>
        </div>
    </>
    );
}