import { useEffect, useState } from "react";
import { FilterStats } from "../../../DTOs/FilterStatsDTO";
import { ChampStats } from "../../../DTOs/StatsDTOs/ChampStatsDTO";
import { ChampImg } from "../ChampImg";
import Tooltip from "rc-tooltip";

import img_top from '../../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../../assets/imgs/roles/Position_Support.png';

import { ReactComponent as SVGMinus } from '../../../assets/svgs/icons/minus-icon.svg'
import { ReactComponent as SVGPlus } from '../../../assets/svgs/icons/plus-icon.svg'

interface activeRoles {

    top: Boolean,
    jungle: Boolean,
    mid: Boolean,
    bot: Boolean,
    support: Boolean

}

interface stats {
    stats: ChampStats[];
    HandleBan: (args: string) => void;
    BanList: string[];
    version: string;
    activeRoles: activeRoles;
    filter: FilterStats;
}


export const ChampStatsOverview = (props: stats) => {
    const [collaps, setCollaps] = useState(true);
    const defaultMaxChamps = 8;
    const [maxChamps, setMaxChamps] = useState(defaultMaxChamps);
    const [champs, setChamps] = useState(props.stats.slice(0, 10))
    const getRoleImg = (role: string) => {
        switch (role) {
            case 'TOP':
                return img_top;
            case 'JUNGLE':
                return img_jungle;
            case 'MIDDLE':
                return img_mid;
            case 'BOTTOM':
                return img_bot;
            case 'UTILITY':
                return img_support;
        }
    }
    useEffect(() => {
        let c: any = [];
        props.stats.forEach((champ: any) => {
            if (champ.stats.winrate >= props.filter.winrate &&
                champ.stats.games >= props.filter.games &&
                champ.stats.kda >= props.filter.kda
            ) {
                let isRole = false;
                champ.roles.forEach((role: string) => {
                    switch (role) {
                        case 'TOP':
                            if (props.activeRoles.top)
                                isRole = true;
                            break;
                        case 'JUNGLE':
                            if (props.activeRoles.jungle)
                                isRole = true;
                            break;
                        case 'MIDDLE':
                            if (props.activeRoles.mid)
                                isRole = true;
                            break;
                        case 'BOTTOM':
                            if (props.activeRoles.bot)
                                isRole = true;
                            break;
                        case 'UTILITY':
                            if (props.activeRoles.support)
                                isRole = true;
                            break;
                    }
                })
                if (isRole) {
                    c.push(champ)
                }
            }
        })

        setChamps(c)
    }, [props.filter, props.activeRoles, props.stats])

    useEffect(()=>{
        if(!collaps){
            setMaxChamps(champs.length);
        }else{
            setMaxChamps(defaultMaxChamps);
        }
    },[collaps, champs.length])
    return (
        <span className="ChampStatsOverview">
            {champs.slice(0, maxChamps).map((champ: any) => {
                return (
                    <span className="ChampStatsOverview-Champ" key={champ.name+champ.kda+champ.games+"key"}>
                        <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<>{champ.roles.map((role: string) => { return (<img className="ChampStatsOverview-Champ-Tooltip-img" src={getRoleImg(role)} alt={role} />) })}</>}>
                            <span className="ChampStatsOverview-Champ-Img">

                                <ChampImg name={champ.name} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} />

                            </span>
                        </Tooltip>
                        <span className="ChampStatsOverview-Champ-Stats">
                            <table>
                                <tr>
                                    <td>games:</td>
                                    <td><b>{champ.stats.games}</b></td>
                                </tr>
                                <tr>
                                    <td>winrate:</td>
                                    <td><b>{champ.stats.winrate as number >= 50 ?
                                        <span className="champion-stats-winrate-positiv">{champ.stats.winrate || (champ.stats.winrate === 0 ? "0%" : "-")}{(champ.stats.winrate && "%") || ""}</span> :
                                        <span className="champion-stats-winrate-negativ">{champ.stats.winrate || (champ.stats.winrate === 0 ? "0%" : "-")}{(champ.stats.winrate && "%") || ""}</span>}
                                    </b></td>
                                </tr>
                                <tr>
                                    <td>K/D/A:</td>
                                    <td>
                                        <b>{champ.stats.kda as number >= 1 ?
                                            champ.stats.kda as number >= 3 ?
                                                <span className="champion-stats-kda-positiv">{champ.stats.kda || (champ.stats.kda === 0 ? "0" : "-")}</span> :
                                                <span className="champion-stats-kda-neutral">{champ.stats.kda || (champ.stats.kda === 0 ? "0" : "-")}</span> :
                                            <span className="champion-stats-kda-negativ">{champ.stats.kda || (champ.stats.kda === 0 ? "0" : "-")}</span>}
                                        </b>
                                    </td>
                                </tr>
                            </table>
                        </span>
                    </span>
                )
            })}
            {champs.length > 8 &&
                <span className="ChampStatsOverview-collaps" onClick={() => { setCollaps(!collaps) }}>
                    {!collaps && <SVGMinus />}
                    {collaps && <SVGPlus />}
                </span>
            }
        </span>
    );
}