import { MasteryDetailsDTO } from "../../../DTOs/StatsDTOs/MasteryDetailsDTO";
import { ChampImg } from "../ChampImg";

import mastery1 from "../../../assets/imgs/masteries/mastery-1.png"
import mastery2 from "../../../assets/imgs/masteries/mastery-2.png"
import mastery3 from "../../../assets/imgs/masteries/mastery-3.png"
import mastery4 from "../../../assets/imgs/masteries/mastery-4.png"
import mastery5 from "../../../assets/imgs/masteries/mastery-5.png"
import mastery6 from "../../../assets/imgs/masteries/mastery-6.png"
import mastery7 from "../../../assets/imgs/masteries/mastery-7.png"
import mastery8 from "../../../assets/imgs/masteries/mastery-8.png"
import mastery9 from "../../../assets/imgs/masteries/mastery-9.png"
import mastery10 from "../../../assets/imgs/masteries/mastery-10.png"
import Tooltip from "rc-tooltip";


interface MasteryCardProps {
    mastery: MasteryDetailsDTO;
    HandleBan: (args: string) => void;
    BanList: string[];
    version: string;
}

export const MasteryCard = (props: MasteryCardProps) => {
    const today = Date.now();
    const dayTime = 86400;
    const weekTime = 604800;
    const monthTime = 2629743;


    let playedAgo = Math.floor((today - props.mastery.lastPlayed) / 1000);
    let playTime = "";
    let monthCount = 0;
    let weekCount = 0;
    let dayCount = 0;

    while (playedAgo >= monthTime) {
        monthCount += 1;
        playedAgo -= monthTime;
    }

    while (playedAgo >= weekTime && monthCount === 0) {
        weekCount += 1;
        playedAgo -= weekTime;
    }

    while (playedAgo >= dayTime && weekCount === 0) {
        dayCount += 1;
        playedAgo -= dayTime;
    }

    if (monthCount > 0) {
        playTime = monthCount + " month"
        if (monthCount > 1)
            playTime += "s"
    } else if (weekCount > 0) {
        playTime = weekCount + " week"
        if (weekCount > 1)
            playTime += "s"
    } else if (dayCount > 0) {
        playTime = dayCount + " day"
        if (dayCount > 1)
            playTime += "s"
    } else {
        playTime = "a few hours"
    }

    playTime += " ago"

    let masteryImg = mastery10;

    switch (props.mastery.level) {
        case 1:
            masteryImg = mastery1;
            break;
        case 2:
            masteryImg = mastery2;
            break;
        case 3:
            masteryImg = mastery3;
            break;
        case 4:
            masteryImg = mastery4;
            break;
        case 5:
            masteryImg = mastery5;
            break;
        case 6:
            masteryImg = mastery6;
            break;
        case 7:
            masteryImg = mastery7;
            break;
        case 8:
            masteryImg = mastery8;
            break;
        case 9:
            masteryImg = mastery9;
            break;

    }

    return (
        <span className="Player-Details-Mastery-Wrapper">
            <span className="Player-Details-Mastery-Imgs">
                <span className="Player-Details-Mastery-Imgs-img">
                    <ChampImg name={props.mastery.name} version={props.version} banList={props.BanList} HandleBan={props.HandleBan} />
                </span>
                <span className="Player-Details-Mastery-Imgs-img">
                    <img src={masteryImg} alt={props.mastery.level.toString()} />
                </span>
            </span>
            <span className="Player-Details-Mastery-Details">
                <span><b>Mastery level:</b> <span className="data">{props.mastery.level.toLocaleString()}</span></span>
                <span><b>Mastery points:</b> <span className="data">{props.mastery.points.toLocaleString()}</span></span>
                <span>
                    <Tooltip mouseEnterDelay={0.25} placement="bottom" overlay={<p>does not relate to<br />Tournament Games</p>}><b>last played:</b></Tooltip> <span className="data">{playTime}</span></span>
            </span>
        </span>
    );
}