import { Region } from "../pages/Tracker";
import config from '../assets/config/config.json';

export interface CopyLinkOptions{
    useGamesFilter: boolean;
    summonernames: string;
    selectedRegion: Region;
    normalGames?: number;
    soloQGames?: number;
    flexQGames?: number;
    clashGames?: number;
    tournamentGames?: number;
    dateValue?: number;
}

export const GetCopyLink = (props: CopyLinkOptions) =>{

        if (props.summonernames === "")
            return "";

        let outString = config.loltracker_url;
        if (props.useGamesFilter) {
            outString += buildSummonernames(props.summonernames, "+") +
                "-" + (props.normalGames || 0) +
                "+" + (props.soloQGames || 0) +
                "+" + (props.flexQGames || 0) +
                "+" + (props.clashGames || 0) +
                "+" + (props.tournamentGames || 0);
        } else {
            outString += buildSummonernames(props.summonernames, "+") + "-" + (props.dateValue || 8);
        }
        outString += "-" + props.selectedRegion.code

        return outString.replaceAll(" ", "%20");
    
}

export const buildSummonernames = (names: string, spaceString: string) => {
    const nameList = names.split(',');
    let namesOut = ''
    nameList.forEach(n => {

        namesOut += n.replace(/^\s+|\s+$/g, "").replace("#", "%23") + spaceString;
    });

    return namesOut.slice(0, (spaceString.length * -1));

}

export interface buildKeyProps{
    currentEndpoint: string;
    useGamesFilter: boolean;
    summonernames: string;
    selectedRegion: Region;
    normalGames?: number;
    soloQGames?: number;
    flexQGames?: number;
    clashGames?: number;
    tournamentGames?: number;
    searchDate?: number;
}

export const buildKey = (props: buildKeyProps) => {
        let tempKey: string = "";

        if(props.searchDate && !props.useGamesFilter){
            tempKey = "/" + props.currentEndpoint + "ByDate?key=9dry5ewhtvsgmkk3njksx84rvry4stgzfggvnnckfg9k3ptwnw89xmv7fg6evenq&summonernames=" + buildSummonernames(props.summonernames, "%SP") + "&timestamp=" + Math.round(props.searchDate) +
                "&region=" + props.selectedRegion.code;
        }
        else  {
            tempKey = "/" + props.currentEndpoint + "?key=9dry5ewhtvsgmkk3njksx84rvry4stgzfggvnnckfg9k3ptwnw89xmv7fg6evenq&summonernames=" + buildSummonernames(props.summonernames, "%SP") +
                "&normalGames=" + (props.normalGames || 0) +
                "&soloQGames=" + (props.soloQGames || 0)+
                "&flexQGames=" + (props.flexQGames || 0)+
                "&clashGames=" + (props.clashGames || 0)+
                "&tournamentGames=" + (props.tournamentGames || 0)+
                "&region=" + props.selectedRegion.code;

        }

        return tempKey;
    }