import { MasteryDetailsDTO } from "../../../DTOs/StatsDTOs/MasteryDetailsDTO";
import { PlayerDetailStatsDTO } from "../../../DTOs/StatsDTOs/PlayerDetailStatsDTO";
import { EloCard } from "./EloCard";
import { MasteryCard } from "./MasteryCard";


interface PlayerDetailsProps {
    details: PlayerDetailStatsDTO;
    HandleBan: (args: string) => void;
    BanList: string[];
    version: string;
}

export const PlayerDetails = (props: PlayerDetailsProps) => {
    
    return (
        <div className="Player-Details">
            <div className="Player-Details-Basic">
                <span className="Player-Details-Basic-Data">
                    <b>Summonername:</b> <span className="data">{props.details.name}</span><br />
                    <b>Summoner Level:</b> <span className="data">{props.details.level}</span>
                </span>
                <span className="Player-Details-Basic-Data">
                    <EloCard rank={props.details.elo.solo} quue={"Solo"} />
                </span>
                <span className="Player-Details-Basic-Data">
                    <EloCard rank={props.details.elo.flex} quue={"Flex"} />
                </span>
            </div>
            <div className="Player-Details-Mastery">
                {props.details.mastery.map((mastery: MasteryDetailsDTO) => {
                    return (<MasteryCard key={mastery.lastPlayed} mastery={mastery} HandleBan={props.HandleBan} BanList={props.BanList} version={props.version} />)
                })}
            </div>
        </div>
    );
}