import Tooltip from 'rc-tooltip';
import config from '../../../assets/config/config.json';
import { ItemsDTO } from '../../../DTOs/MatchHistoryDTOs/ItemsDTO';

interface ItemProps {
    itemID: number;
    version: string;
    itemJson: ItemsDTO | undefined;
}

export const Item = (props: ItemProps) => {

    const getItemData = () => {
        return props.itemJson?.data[props.itemID.toString()]
    }

    const ItemToolTip = () => {
        return (<div className='tooltip-item'>
            <span className='tooltip-item-name'>
                <span className='tooltip-item-name-img'>
                    <img alt={""} className="itemImg" src={config.riot_champ_img_base_url + props.version + "/img/item/" + props.itemID + ".png"} />
                </span>
                <span className='tooltip-item-name-header'>
                    <span className='tooltip-item-name-header-name'>{getItemData()?.name}</span>
                    <span className='tooltip-item-name-header-cost'>
                        <i className='pi pi-shopping-cart' />
                        <span>{getItemData()?.gold?.total}</span>
                    </span>
                </span>
            </span>
            <span className='tooltip-item-description'>
                <span className="rune-txt" dangerouslySetInnerHTML={{ __html: (getItemData()?.description || "") }} />
            </span>
        </div>);
    }

    return (<>
        <Tooltip mouseEnterDelay={0.25} overlay={ItemToolTip()} placement='left' zIndex={9999}>
            <img alt={""} className="itemImg" src={config.riot_champ_img_base_url + props.version + "/img/item/" + props.itemID + ".png"} />
        </Tooltip>
    </>)
}