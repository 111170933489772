import img_top from '../../assets/imgs/roles/Position_Top.png';
import img_jungle from '../../assets/imgs/roles/Position_Jungle.png';
import img_mid from '../../assets/imgs/roles/Position_Mid.png';
import img_bot from '../../assets/imgs/roles/Position_Bot.png';
import img_support from '../../assets/imgs/roles/Position_Support.png';
import { Knob } from 'primereact/knob';
import Tooltip from 'rc-tooltip';
import { RoleStats } from '../../DTOs/RoleStatsDTO';
import { activeRolesValues } from '../../DTOs/ActiveRolesDTO';




export const RoleStatsElement = (props: RoleStats) => {

  let roleImg = img_top;
  let roleName = "None"
  let roleValue:activeRolesValues = {...props.activeRoles};
  let activeClass = ""
    if (props.role === "TOP") {
      roleImg = img_top
      roleName = "Top";
      roleValue = {...props.activeRoles, top: !props.activeRoles.top}
      if(roleValue.top){
        activeClass = "RoleStats-Img-notActive"
      }
    }
    else if (props.role === "JUNGLE") {
      roleImg = img_jungle
      roleName = "Jungle";
      roleValue = {...props.activeRoles, jungle: !props.activeRoles.jungle}
      if(roleValue.jungle){
        activeClass = "RoleStats-Img-notActive"
      }
    }
    else if (props.role === "MIDDLE") {
      roleImg = img_mid
      roleName = "Mid";
      roleValue = {...props.activeRoles, mid: !props.activeRoles.mid}
      if(roleValue.mid){
        activeClass = "RoleStats-Img-notActive"
      }
    }
    else if (props.role === "BOTTOM") {
      roleImg = img_bot
      roleName = "Bot";
      roleValue = {...props.activeRoles, bot: !props.activeRoles.bot}
      if(roleValue.bot){
        activeClass = "RoleStats-Img-notActive"
      }
    }
    else if (props.role === "UTILITY") {
      roleImg = img_support
      roleName = "Support";
      roleValue = {...props.activeRoles, support: !props.activeRoles.support}
      if(roleValue.support){
        activeClass = "RoleStats-Img-notActive"
      }
    }
    else 
      return <></>

  return (<div className='RoleStats'>
    {props.games !== 0 &&
    <span>
    <Tooltip mouseEnterDelay={0.25} placement="bottom" overlay={<p>Activate/Deactivate Role</p>}>
        <img className={activeClass + " mouse-pointer"} alt="" src={roleImg} onClick={() => props.setter(roleValue)}/>
    </Tooltip>
    </span>}
    {props.games === 0 &&
    <span>
        <img className={"RoleStats-Img-notActive mouse-pointer"} alt="" src={roleImg}/>
    </span>}
    <span>
      <Tooltip mouseEnterDelay={0.25} placement="bottom" overlay={<p>Role: {roleName}<br />games played: {props.games}&#47;{props.allGames}<br />K/D/A: {props.kda}<br />Winrate: {props.winrate}%</p>}>
        {props.winrate < 45 ?
          <Knob value={Math.round((props.games / props.allGames)*100)} valueTemplate={'{value}%'} readOnly valueColor="#fa7970" rangeColor="#89929b" /> :
          props.winrate <= 55 ?
            <Knob value={Math.round((props.games / props.allGames)*100)} valueTemplate={'{value}%'} readOnly valueColor="#faa356" rangeColor="#89929b" /> :
            <Knob value={Math.round((props.games / props.allGames)*100)} valueTemplate={'{value}%'} readOnly valueColor="#7ce38b" rangeColor="#89929b" />}
      </Tooltip>
    </span>
  </div>)
}